import React from 'react';
import {Row, Col} from 'reactstrap';
import './style.scss';
import SVLogo from "../../../styles/assets/images/sv/sv_logo.svg";
import WRLogoBlack from "../../../styles/assets/images/menu/WRLogoBlack.svg";
import HeaderMenu from "../../common/HeaderMenu";
import {BASE_SILAVETRA_URL} from "../../../lib/api/globalState";
import {t} from "i18next";
import {useApi} from "../../../lib/api/useApi";
import {Link} from "react-router-dom";
import {isRus} from "../../../lib/utils/country";

const Header = ({label}) => {
    const { getGlobalState } = useApi();
    const globalState = getGlobalState();
    const isRoleTablet = globalState.clientRole === 'tablet';
    const logo = isRus ? SVLogo : WRLogoBlack

    return (
    <Row className='header justify-content-start align-items-top no-gutters mt-1 '>
       <>
            <Col className='header__logo-content' xs="mt-1" style={{width: '80%'}}>
                <Row className="justify-content-start align-items-center">
                    <Col className='header__logo-wrapper col-auto'>
                        <span className="header__logo">
                            {isRoleTablet ?
                                <Link to="/tablet">
                                    <img src={logo} alt="" />
                                </Link>
                            :
                                <a href={BASE_SILAVETRA_URL} target="_blank">
                                    <img src={logo} alt="" />
                                </a>
                            }
                        </span>
                    </Col>
                    <Col 
                         className={`col-auto d-flex align-items-center justify-content-center`}
                    >
                        {isRoleTablet ?
                            <Link to="/tablet" className="header__link">
                                <span className={'header__step'}>{t(label)}</span>
                            </Link>
                            : <span className={'header__step'}>{t(label)}</span>
                        }
                    </Col>
                </Row>
            </Col>
            <Col className="pl-0 pl-sm-4 " style={{width: '20%'}}>
                <div className="d-flex justify-content-end">
                    <HeaderMenu />
                </div>
            </Col>
        </>
    </Row>
  )
}

export default Header;