import React, {useCallback, useEffect, useState} from "react";
import {Input, Label} from 'reactstrap';
import { useForm } from "react-hook-form";
import chat from "../../../styles/assets/images/sv/chat.svg";
import './style.scss'
import {normalizeCurrency, regexes} from '../../../lib/utils/helpers';
import {useHistory, useLocation} from "react-router-dom";
import FormButton from "../../generic/buttons/main/index2";
import SubscriptionInfo from './SubscriptionsInfo'
import _ from 'lodash'
import {features} from "../../../lib/api/globalState";
// @ts-ignore
import {PRODUCT_TYPES} from "sv-common/constants/certificates";
import {TSubscription, useTypedPublicEndpoints} from "../../../lib/api/useTypedPublicEndpoint";
import {useOrdersApi} from "../../../lib/api/useOrdersApi";
import {usePaymentsApi} from "../../../lib/api/usePaymentsApi";
import {TCertificate, useCertificatesApi} from "../../../lib/api/useCertificatesApi";
import useCountry from "../../../lib/utils/hooks/useCountry";
import {ICertificate, ICurrency, IPromocode, TOrder, TPromoAndCertDiscount} from "../../../lib/utils/types";
import {useLoaderState} from "../../../lib/api/loaderState";
import external from "../../../styles/assets/images/sv/external.svg";
import i18next from "i18next";
import Divider from "../../generic/Divider";
import SubscriptionRange from "./SubscriptionRange";
import {useInjection} from 'brandi-react';
import {AuthModelStoreToken} from '../../../entities/auth/model';
import {getCookie} from '../../../lib/utils/cookies';
import {setInvalidReason} from "../../common/PayBlock";
import {useOrder} from "../../../lib/utils/hooks/useOrder";
import {PayBlockModelStoreToken} from "../../common/PayBlock/models/PayBlockModel/index.model";
import {observer} from "mobx-react-lite";
import {GlobalSettingsModelStoreToken} from "../../../entities/globalConfig/model";
import SubscriptionToPayment from "./SubscriptionToPayment";
import PaymentMethodCertComponentV2 from "components/common/PayBlock/paymentMethods/certV2";

type TSubscriptionForm = {
    setSubscription: (s: TSubscription) => void,
    certificateApplied: TCertificate,
    setCertificateApplied: (c: ICertificate | undefined | null) => void,
    setPromoApplied: (c: IPromocode | undefined | null) => void,
    email: string,
    subscription: TSubscription,
    price: number,
    userSubscriptionId: number,
    currencies: [ICurrency],
    entity: { [key: string]: any },
    order: TOrder,
    depositSum: number,
    promoAndCertDiscount: TPromoAndCertDiscount,
    promoApplied: IPromocode,
}

type TSubscriptionFormWithCountry = TSubscriptionForm & { country: string }

const SubscriptionFormWithCountry = (props: TSubscriptionForm) => {
    const country = useCountry();
    return <SubscriptionForm {...props} country={country}/>
}

const SubscriptionForm = observer(({
                                       setSubscription, certificateApplied, setCertificateApplied, country,
                                       email, subscription, price, userSubscriptionId, currencies, entity, order,
                                       depositSum, setPromoApplied, promoAndCertDiscount, promoApplied,
                                   }: TSubscriptionFormWithCountry) => {
    const {t} = i18next;

    const payBlockModel = useInjection(PayBlockModelStoreToken);
    const authM = useInjection(AuthModelStoreToken);
    const globalSettings = useInjection(GlobalSettingsModelStoreToken);
    const {checkCertificate} = useCertificatesApi();
    const {getSubscriptions} = useTypedPublicEndpoints();
    const {createOrder} = useOrdersApi();
    const {createPayment} = usePaymentsApi();
    // @ts-ignore
    const {pathname, query} = useLocation();
    const history = useHistory();
    const {setPromocodeOrCertificate} = useOrder();

    const [responseError, setResponseError] = useState<string>();
    const [subscriptions, setSubscriptions] = useState<TSubscription[] | undefined>(undefined);
    const [allSubscriptions, setAllSubscriptions] = useState<TSubscription[] | null>(null);
    const [currentSubscriptionData, setCurrentSubscriptionData] = useState<{ [key: string]: any } | undefined>({})

    const {setIsLoading} = useLoaderState();

    const currency = normalizeCurrency(entity?.price_currency, currencies) || '';

    useEffect(() => {
        setIsLoading(true);
        getSubscriptions()
                .then(res => {
                    const {subscriptions} = res;
                    const subscriptionsFiltered = subscriptions.filter(subscription => subscription.is_available_for_sale)

                    setSubscriptions(subscriptionsFiltered);

                    setAllSubscriptions(subscriptions)
                    if (price) {
                        const chosenSubscription = subscriptionsFiltered.find(item => +item.price_value === +price)
                        if (!chosenSubscription) return;
                        setSubscription(chosenSubscription);
                    } else {
                        setSubscription(subscriptionsFiltered[0])
                    }
                })
                .finally(() => setIsLoading(false))
    }, [country])

    const {register, errors, handleSubmit} = useForm({mode: "onBlur", reValidateMode: "onChange"});

    const paymentHandler = (orderId: number) => {
        const redirectURL = query.redirectURL ? `&redirectURL=${query.redirectURL}` : '';
        const redirectOrderId = query.redirectURL ? `&redirectOrderId=${query.redirectOrderId}` : '';
        const paymentQueryParams = `?orderId=${orderId}&step=2${redirectURL}${redirectOrderId}`;

        createPayment(orderId, pathname + paymentQueryParams)
                .then((res) => (window.location.href = res.url))
                .catch((e) => {
                    console.log(e);
                })
    };

    const onCreate = () => {
        authM.ensureLoggedIn(() => {
            setIsLoading(true)
            const participant = {email};
            const payloadData = {
                ...order,
                participants: [participant],
                product: 'subscriptions',
                productId: subscription.id,
                ym_client_id: getCookie(`_ym_uid`),
                certificateApplied: certificateApplied?.id,
                promocodeApplied: promoApplied?.id,
            };
            return createOrder(payloadData)
                    .then(res => {
                        if (features['SV-1569']) {
                            history.push('/error')
                        } else {
                            paymentHandler(res.orderId)
                        }
                    }).catch(res => {
                        setResponseError(res.response.data.message)
                    })
                    .finally(() => setIsLoading(false))
        })
    }

    const verifyPromocode = useCallback((value: string, type?: string, captcha?: string) => {
        if (entity?.id) {
            setPromocodeOrCertificate(
                    PRODUCT_TYPES.SUBSCRIPTIONS,
                    value,
                    entity.id,
                    0,
                    undefined,
                    onSetPromo,
                    onSetCertificate,
                    undefined,
                    undefined,
                    (reason: string, type: string) => setInvalidReason(reason, type, payBlockModel),
                    undefined,
                    payBlockModel.resetAppliedDiscounts,
                    type,
                    captcha,
            );
        }
    }, [entity?.id, payBlockModel, order, setCertificateApplied]);


    useEffect(() => {
        allSubscriptions && setCurrentSubscriptionData(allSubscriptions.find( subscription => subscription.id === userSubscriptionId ))
    }, [subscriptions, userSubscriptionId])

    const onSetPromo = (v: IPromocode = {} as IPromocode) => {
        payBlockModel.promo.setValue(v);
        payBlockModel.promo.setQuery(v.name);
        setPromoApplied(v);
    }

    const onSetCertificate = (v: ICertificate = {} as ICertificate) => {
        payBlockModel.cert.setValue(v);
        payBlockModel.cert.setQuery(v.name);
        setCertificateApplied(v);
    }

    const [isChecked, setIsChecked] = useState(false); 

    
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked); 
    };

    return (
        // @ts-ignore
		<div className="subscription">
			<p className="subscription-title">{t('subscriptions.title')}</p>
            {!!depositSum && <div className="mt-3 mb-4">
                    <div className='input-label mb-2'>
                        {t('subscriptions.subscriptionRemainder')}
                    </div>
                    <div className="subscription_deposite">{depositSum} {currency}</div>
            </div>}
            <Divider noMargin />
            <form onSubmit={handleSubmit(onCreate)}>
                <div className="price__wrapper">
                    <SubscriptionRange
                            subscriptions={subscriptions}
                            subscription={subscription}
                            setSubscription={setSubscription}
                            register={register}
                            currencies={currencies}
                            currentValue={subscription?.price_value}
                            renderSlider={({handleChange, min, max}) =>
                                <>
                                    <Input
                                            type="range"
                                            id={'price'}
                                            value={subscription?.price_value}
                                            name={'value'}
                                            min={min}
                                            max={max}
                                            step={1}
                                            onChange={(e) => handleChange(+e.target.value)}
                                            innerRef={register && register({required: false})}
                                            style={{'--range-fill': `${subscription?.price_value/(max || 100000) * 100}%`} as React.CSSProperties }
                                    />
                                </>}/>
                    <SubscriptionInfo
                                    currentSubscriptionData={currentSubscriptionData}
                                    subscription={subscription}
                                    currencies={currencies}
                                    depositSum={depositSum}
                                    currency={currency}
                    />
                </div>
                <Divider noMargin/>
                <div className='checkout__input-wrapper'>
                    <div className='payment-methods-block'>
                        <PaymentMethodCertComponentV2
                            order={order}
                            isShowPromocode={globalSettings.config['promo_subscription_available']}
                            promoAndCertDiscount={promoAndCertDiscount}
                            verifyPromocode={verifyPromocode}
                            setCertificateApplied={onSetCertificate}
                            setPromocodeApplied={onSetPromo}
                            certRequired={false}
                        />
                    </div>
                </div>
                <Divider noMargin/>
                <div className="checkout__input-wrapper radio-input public-offer-consent">
                    <Input
                            type="checkbox"
                            id="publicOfferConsent"
                            name="publicOfferConsent"
                            innerRef={register({
                                required: true,
                            })}
                            onChange={handleCheckboxChange}
                    />
                    <Label htmlFor="publicOfferConsent">
                        <a href={t('subscriptions.publicOffer') as string} target="_blank"
                           rel="noopener noreferrer">
                            {t('inputs.consents.publicOffer')}<img src={external} alt=""/>
                        </a>
                    </Label>

                    <span className={errors.publicOfferConsent ? "error-label" : "error-label d-none"}>
                    {errors.publicOfferConsent?.type === "required" && t("inputs.consents.publicOfferError")}
                </span>
                </div>
                <Divider noMargin/>
                <div className="checkout__to_payment">
                    <div className="checkout__wrapper">
                        <SubscriptionToPayment
                            entity={entity}
                            currencies={currencies}
                            oldPrice={price}
                            price={promoAndCertDiscount.price}
                        />
                        <div className="buy_button_wrapper d-flex align-items-center flex-column w-100">
                            <FormButton className={!isChecked ? "buy-button button-disabled" : "buy-button"} disabled={!isChecked} value={t('common.buy')} type="submit"/>
                            {responseError && <small className="text-danger">{responseError}</small>}
                        </div>
                    </div>
                </div>
                <Divider noMargin/>
                <div className="is-help-needed">
                    <div className='input-label mb-2'>
                        {t('subscriptions.isHelpNeeded')}
                    </div>
                    <div className="d-inline">{t('subscriptions.writeToUs')}</div>
                    <a href={t('subscriptions.telegramLink') as string} className="link_button" target="_blank">{t('subscriptions.telegram')}</a>,
                    <div className="d-inline">{t('subscriptions.callByNumber')}</div>
                    <a href={`tel:${t('subscriptions.phone')}`} className="link_button">{t('subscriptions.phone')}</a>
                </div>
                <div className="chat_open_button">
                    <img src={chat}></img>
                </div>
            </form>
        </div>
);
});

export default SubscriptionFormWithCountry;
